<script setup lang="ts">
import {
  AppstoreOutlined,
  FileDoneOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";

const route = useRoute();
const page = route.path as string;
</script>
<template>
  <div
    class="h-[14%] w-[100%] flex bg-white gap-8 justify-center shadow-[0_-9px_17px_-5px_rgba(0,0,0,0.2)] rounded-2xl pt-6 pb-2 mt-4 lg:shadow-none"
  >
    <router-link to="/">
      <div class="flex flex-col items-center gap-3 text-black">
        <appstore-outlined
          class="text-lg p-2 rounded"
          :class="{ 'bg-[#ffd401] p-0': page === '/' }"
        />
        <p class="text-xs">{{ $t("profile.start") }}</p>
      </div>
    </router-link>
    <router-link to="/tasks">
      <div class="flex flex-col items-center gap-3 text-black">
        <file-done-outlined
          class="text-lg p-2 rounded"
          :class="{ 'bg-[#ffd401] p-0': page.startsWith('/tasks') }"
        />
        <p class="text-xs">{{ $t("profile.tasks") }}</p>
      </div>
    </router-link>
    <router-link to="/profile/worktypes">
      <div class="flex flex-col items-center gap-3 text-black">
        <unordered-list-outlined
          class="text-lg p-2 rounded"
          :class="{ 'bg-[#ffd401] p-0': page === '/profile/worktypes' }"
        />
        <p class="text-xs">{{ $t("profile.task_types") }}</p>
      </div>
    </router-link>
    <router-link to="/profile/">
      <div class="flex flex-col items-center gap-3 text-black">
        <user-outlined
          class="text-lg p-2 rounded"
          :class="{ 'bg-[#ffd401] p-0': page === '/profile/' }"
        />
        <p class="text-xs">{{ $t("common.button.profile") }}</p>
      </div>
    </router-link>
  </div>
</template>
